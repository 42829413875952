import React from "react";

function AboutMe() {
  return (
    <div className="aboutinfo">
      <header className="about">Diana Marcelin</header>
      <p>
        I’m a passionate and dedicated junior developer driven by the challenges
        of programming and a strong desire to learn and grow in the technology
        field. My passion for software development extends beyond the classroom
        I continuously explore emerging technologies, believing that staying
        up-to-date is vital for creating innovative solutions. I value teamwork,
        effective communication, and enjoy turning ideas into elegant,
        user-friendly software applications through collaboration. Eager to
        contribute to a forward-thinking organization, I bring a strong work
        ethic, attention to detail, and a problem-solving mindset, along with a
        passion for learning and delivering high-quality code. Let's connect,
        collaborate, and create remarkable projects together!
      </p>
    </div>
  );
}

export default AboutMe;
