import React from "react";
function Footer() {
  return (
    <div className="footer">
      <footer>
        <p>&copy; 2023, Diana Marcelin</p>
      </footer>
    </div>
  );
}
export default Footer;
