import React from "react";
import { Button } from "antd";
import Inspired from "../assets/Inspired-Cooking.png";
import Todo from "../assets/Todo.png";

function Projects() {
  return (
    <p>
      <div>
        <h2 className="project">Projects</h2>
        <Button href="https://lets-eat-71558.web.app/">
          <img
            className="Inspired"
            src={Inspired}
            alt="Cutting board with avocado,tomatoes,mushrooms,eggs, green onion,spinach, and a knife"
          />
        </Button>
      </div>
      <div>
        <Button href="https://github.com/marcelindia/pinia-vue">
          <img
            src={Todo}
            className="List"
            alt="Photo of green and white checklist."
          />
        </Button>
      </div>
    </p>
  );
}
export default Projects;
