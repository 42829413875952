import React from "react";
import { GithubOutlined, LinkedinOutlined } from "@ant-design/icons";
import "./Socials.css";

function Socials() {
  return (
    <div className="socials">
      <p>
        Let's Connect: <br />
        <a href="https://www.linkedin.com/in/diana-marcelin/">
          <LinkedinOutlined />
        </a>
        <a href="https://github.com/marcelindia">
          <GithubOutlined />
        </a>
      </p>
    </div>
  );
}
export default Socials;
