import "./App.css";
import React from "react";
import Curls from "./assets/Headshot.jpg";
import Socials from "./components/Socials";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import AboutMe from "./components/AboutMe";

function App() {
  return (
    <>
      <div className="App">
        <div className="photo">
          <img
            src={Curls}
            alt="brown skin woman with curly hair, sitting on blue chair, smiling, wearing grey shirt and burgundy pants"
          />

          <AboutMe />
          <Projects />
          <Socials />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
